import { z } from "zod";

const deploymentEnvironmentEnum = z.enum(["local", "dev", "staging", "prod"]);
export type DeploymentEnvironment = z.infer<typeof deploymentEnvironmentEnum>;

const environmentVariablesSchema = z.object({
  VITE_ENVIRONMENT: deploymentEnvironmentEnum,
  VITE_MOCK_DATA_ENABLED: z
    .string()
    .optional()
    .transform(value => value === "1"),
  VITE_GRAPHQL_ENDPOINT_URL: z.string(),
  VITE_AUTH_DOMAIN: z.string(),
  VITE_AUTH_AUDIENCE: z.string(),
  VITE_AUTH_CLIENT_ID: z.string(),
  VITE_AUTH_CALLBACK_REDIRECT_URL: z.string(),
  VITE_AUTH_LOGOUT_REDIRECT_URL: z.string(),
  /** optionals */
  VITE_LOG_ROCKET_APP_ID: z.string().optional(),
  VITE_SENTRY_DNS: z.string().optional(),
  VITE_SENTRY_AUTH_TOKEN: z.string().optional(),
  VITE_SENTRY_PROJECT: z.string().optional(),
  VITE_SENTRY_ORGANIZATION_SLUG: z.string().optional(),
});

export const environmentVariables = environmentVariablesSchema.parse(import.meta.env);

const BASE_URL = window.location.origin;

function buildGraphqlEndpointUrl() {
  if (environmentVariables.VITE_ENVIRONMENT === "dev" && BASE_URL.includes("adn-eph")) {
    return null;
  }
  return environmentVariables.VITE_GRAPHQL_ENDPOINT_URL;
}

export const config = {
  environment: environmentVariables.VITE_ENVIRONMENT,
  graphqlEndpointUrl: buildGraphqlEndpointUrl() ?? "/api/graphql",
  baseUrl: BASE_URL,
  mockDataEnabled: environmentVariables.VITE_MOCK_DATA_ENABLED,
  localStoragePrefix: "voodoo-adn",
  auth0: {
    domain: environmentVariables.VITE_AUTH_DOMAIN,
    issuer: `https://${environmentVariables.VITE_AUTH_DOMAIN}/`,
    jwksUrl: `https://${environmentVariables.VITE_AUTH_DOMAIN}/.well-known/jwks.json`,
    audience: environmentVariables.VITE_AUTH_AUDIENCE,
    clientId: environmentVariables.VITE_AUTH_CLIENT_ID,
    callbackRedirectUrl: `${BASE_URL}${environmentVariables.VITE_AUTH_CALLBACK_REDIRECT_URL}`,
    logoutRedirectUrl: `${BASE_URL}${environmentVariables.VITE_AUTH_LOGOUT_REDIRECT_URL}`,
  },
  loggers: {
    sentryDns: environmentVariables.VITE_SENTRY_DNS,
    logRocketAppId: environmentVariables.VITE_LOG_ROCKET_APP_ID,
  },
};
