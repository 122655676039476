import { buildQuery, buildQueryKey, graphql, runGraphqlQuery } from "@/graphql";

const query = graphql(/* GraphQL */ `
  query DashboardGlobalSpend {
    globalSpend {
      lastWeekTotalSpend
      totalSpend
      values {
        date
        value
      }
    }
  }
`);

function getQueryOptions() {
  return {
    queryKey: buildQueryKey(query),
    async queryFn() {
      return runGraphqlQuery(query);
    },
  };
}

export function useDashboardGlobalSpendQuery() {
  return buildQuery({
    ...getQueryOptions(),
  });
}
