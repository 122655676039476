<script lang="ts" setup>
import { format, subDays } from "date-fns";
import { computed, ref } from "vue";

import AppChip from "@/components/business/AppChip/AppChip.vue";
import ChartsEvolutionTag from "@/components/business/Charts/components/ChartsEvolutionTag/ChartsEvolutionTag.vue";
import DashboardWidget from "@/components/business/DashboardWidget/DashboardWidget.vue";
import VdRouterLink from "@/components/structures/VdRouterLink/VdRouterLink.vue";
import type { AppFragment, TopSpenderCampaignFragment, TopSpendersQueryVariables } from "@/graphql";
import links from "@/router/links";
import { prettifyCurrency } from "@/utils/prettifiers/prettify-currency";

import { useTopSpendersQuery } from "../../queries/top-spenders.graphql";
import type { TopSpenderData } from "./types";

const queryParams = ref<TopSpendersQueryVariables>({
  first: 10,
  reportStartDate: format(subDays(new Date(), 8), "yyyy-MM-dd"),
  reportEndDate: format(subDays(new Date(), 1), "yyyy-MM-dd"),
  reportSorts: "-billableSpend",
});

const { data, isLoading } = useTopSpendersQuery(queryParams, { enabled: ref(true) });

const topSpendersCampaigns = computed<TopSpenderCampaignFragment[]>(() => {
  return data?.value?.campaigns.edges?.map(edge => edge?.node).filter(Boolean) ?? [];
});

const spendersEvolutionVariables = computed<TopSpendersQueryVariables>(() => ({
  campaignIds: topSpendersCampaigns.value.map(campaign => campaign.campaignId),
  reportStartDate: format(subDays(new Date(), 15), "yyyy-MM-dd"),
  reportEndDate: format(subDays(new Date(), 8), "yyyy-MM-dd"),
}));

const isEnabledEvolutionsQuery = computed(() => topSpendersCampaigns.value.length > 0);

const { data: evolutionData, isLoading: evolutionsLoading } = useTopSpendersQuery(spendersEvolutionVariables, { enabled: isEnabledEvolutionsQuery });

const topSpendersData = computed<TopSpenderData[]>(() =>
  topSpendersCampaigns.value.map(campaign => ({
    campaign: campaign.campaign,
    billableSpend: campaign.report?.billableSpend ?? 0,
    lastSpend: evolutionData.value?.campaigns.edges?.find(edge => edge?.node?.campaignId === campaign.campaignId)?.node?.report?.billableSpend ?? 0,
  }
  )));

const appsByCampaignId = computed<Record<string, AppFragment>>(() => {
  return (topSpendersData.value ?? []).reduce<Record<string, AppFragment>>((acc, item) => {
    if (item.campaign.__typename === "PerformanceCampaign" && item.campaign.app) {
      acc[item.campaign.id] = item.campaign.app;
    }

    return acc;
  }, {});
});
</script>

<template>
  <DashboardWidget
    :loading="isLoading"
    title="Top Spenders"
    subtitle="Top 10 spending campaigns (last 7 days)"
  >
    <div class="flex flex-col">
      <div
        v-for="(item, index) in topSpendersData"
        :key="item.campaign.id"
        class="flex items-center justify-between p-2"
        :class="{ 'bg-primary-50': index % 2 === 0 }"
      >
        <div class="flex items-center justify-start gap-2">
          <VdRouterLink
            :to="{
              name: links.campaigns.details.countries,
              params: { campaignId: item.campaign.id },
            }"
            class="flex items-center gap-2"
          >
            <AppChip
              v-if="appsByCampaignId[item.campaign.id]"
              :icon-url="appsByCampaignId[item.campaign.id]?.iconUrl"
              :store="appsByCampaignId[item.campaign.id]?.store"
              size="small"
              wrap
            />

            <span>{{ item.campaign.name }}</span>
          </VdRouterLink>
        </div>

        <div class="flex items-center gap-3">
          <span class="font-semibold">{{ prettifyCurrency(item.billableSpend) }}</span>

          <ChartsEvolutionTag
            :current-value="item.billableSpend"
            :last-value="item.lastSpend"
            :loading="evolutionsLoading"
          />
        </div>
      </div>
    </div>
  </DashboardWidget>
</template>
