<script setup lang="ts">
import type { ApexOptions } from "apexcharts";
import format from "date-fns/format";
import { computed, ref } from "vue";
import VueApexCharts from "vue3-apexcharts";

import ChartsEvolutionTag from "@/components/business/Charts/components/ChartsEvolutionTag/ChartsEvolutionTag.vue";
import DashboardWidget from "@/components/business/DashboardWidget/DashboardWidget.vue";
import { prettifyCurrency } from "@/utils/prettifiers/prettify-currency";

import { useDashboardGlobalSpendQuery } from "../../queries/dashboard-global-spend.query.graphql";

const chart = ref<ApexCharts | null>(null);

const { data, isPending } = useDashboardGlobalSpendQuery();

const chartData = computed<ApexAxisChartSeries | null>(() => {
  const documentStyle = getComputedStyle(document.documentElement);
  const primaryColor = documentStyle.getPropertyValue("--primary-color");

  if (data.value?.globalSpend == null) {
    return null;
  }

  const apexAxisConf: ApexAxisChartSeries = [{
    name: "Global Spend",
    color: primaryColor,
    data: data.value.globalSpend.values.map((value) => {
      const date = value.date * 1000;

      return {
        x: new Date(date).setHours(0, 0, 0, 0),
        y: value.value,
      };
    }).sort((a, b) => a.x - b.x),
  }] satisfies ApexAxisChartSeries;

  return apexAxisConf;
});

const chartOptions = computed<ApexOptions>(() => {
  return {
    chart: {
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 400,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      },
    },
    tooltip: {
      theme: "light",
      custom({ series, seriesIndex, dataPointIndex, w }) {
        const date = format(new Date(w.config.series[seriesIndex].data[dataPointIndex].x), "eee, dd MMM yyyy");

        return `<div class="bg-white shadow-lg shadow-primary/10 rounded-lg p-3 pr-5">`
          + `<div class="text-xs text-slate-500">${date}</div>`
          + `<strong class="text-xl leading-tight">${prettifyCurrency(series[seriesIndex][dataPointIndex])}</strong>`
          + "</div>";
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter(value) {
          return prettifyCurrency(value, { maximumFractionDigits: 0 });
        },
      },
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      labels: {
        datetimeUTC: false,
        format: "ddd dd",
      },
    },
  } satisfies ApexOptions;
});
</script>

<template>
  <DashboardWidget
    :loading="isPending"
    title="Global Spend"
    subtitle="Last 7 days"
  >
    <template #title-end>
      <div class="flex flex-col">
        <div class="mb-1 flex items-center justify-end gap-3">
          <ChartsEvolutionTag
            v-if="data?.globalSpend.lastWeekTotalSpend"
            :current-value="data.globalSpend.totalSpend"
            :last-value="data.globalSpend.lastWeekTotalSpend"
            :loading="isPending"
          />

          <span class="text-2xl font-bold leading-none">{{ data?.globalSpend.totalSpend ? prettifyCurrency(data.globalSpend.totalSpend) : "-" }}</span>
        </div>

        <div class="text-right text-sm uppercase text-slate-500">
          All campaigns total
        </div>
      </div>
    </template>

    <template #default>
      <div class="min-h-104 flex-1">
        <VueApexCharts
          v-if="chartData"
          ref="chart"
          type="area"
          :options="chartOptions"
          :series="chartData"
          height="100%"
        />
      </div>
    </template>
  </DashboardWidget>
</template>
